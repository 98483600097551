import React from 'react'
import "./moreupcoming.css"
import MoreUpcoming1 from './MoreUpcoming1'
import MoreUpcoming2 from './MoreUpcoming2'
import MoreUpcoming3 from './MoreUpcoming3'
import MoreUpcoming4 from './MoreUpcoming4'
const MoreUpcomingEven = () => {
  return (
    <div>
        <MoreUpcoming1/>
        <MoreUpcoming2/>
        <MoreUpcoming3/>
        <MoreUpcoming4/>
    </div>
  )
}

export default MoreUpcomingEven
