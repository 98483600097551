import React from 'react'
import "./moresection.css"
import Moresection from './Moresection'
import MoreSection2 from './MoreSection2/MoreSection2'
import MoreSection3 from './MoreSection3/MoreSection3'


const MainMore = () => {
  return (
    <div className='moresection'>
      <Moresection/>
      <MoreSection2/>
      <MoreSection3/>
    </div>
  )
}

export default MainMore
