import React from 'react'
import Aboutus from "../../../assets/about/aboutus.png"
import "./moreabout.css"
const MoreAbout = () => {
  return (
    <div className='moreabout'>
      <div className='moreaboutimg'>
        {/* Image */}
        <img src={Aboutus} alt='Career' />
        {/* Dark Overlay */}
        <div className='overlay'></div>
      </div>
      <div className='moreaboutcontaint'>
        <h1>About</h1>
        <h1 className='abouth1'>FBT Study Abroad</h1>
      </div>
    </div>
  )
}

export default MoreAbout
