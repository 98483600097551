import React from 'react';
import "./services.css";
import Serviceimg from "../../assets/services/ServicesHeroImage.png";

const Service = () => {
  return (
    <div className='serviceshero'>
      <div className='servicesimg'>
        {/* Image */}
        <img src={Serviceimg} alt='Service' />
        {/* Dark Overlay */}
        <div className='overlay'></div>
        <div className='serivces-con'>
          <h3>Guiding You Every Step
            of The Way</h3>
        </div>
      </div>
    </div>
  );
}

export default Service;
