import React, { useState } from 'react';
import "./filter.css";

const Filter = () => {
  const [activeTab, setActiveTab] = useState('Program Level'); // State to track active tab
  const [selectedOptions, setSelectedOptions] = useState([]);  // Track selected buttons

  // Handle button selection
  const handleSelect = (option) => {
    const isSelected = selectedOptions.includes(option);
    if (isSelected) {
      setSelectedOptions(selectedOptions.filter(item => item !== option)); // Deselect
    } else {
      setSelectedOptions([...selectedOptions, option]); // Select
    }
  };
  const handleProgramLevelSelect = (option) => {
    setSelectedOptions([option]); // Set the selected option, allowing only one selection at a time
  };

  // Handle Clear All functionality
  const clearAll = () => {
    setSelectedOptions([]); // Clear all selections
  };

  // Render content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'Program Level':
        return (
          <div className="filter-content">
            <button
              className={`fil-btn ${selectedOptions.includes('Masters') ? 'selected' : ''}`}
              onClick={() => handleProgramLevelSelect('Masters')}
            >
              Masters
            </button>
            <button
              className={`fil-btn ${selectedOptions.includes('Bachelors') ? 'selected' : ''}`}
              onClick={() => handleProgramLevelSelect('Bachelors')} 
            >
              Bachelors
            </button>
          </div>
        );
      case 'Courses':
        return (
          <div className="filter-content">
            <button
              className={`fil-btn ${selectedOptions.includes('Computer Science') ? 'selected' : ''}`}
              onClick={() => handleSelect('Computer Science')}
            >
              Computer Science
            </button>
            <button
              className={`fil-btn ${selectedOptions.includes('Data Science') ? 'selected' : ''}`}
              onClick={() => handleSelect('Data Science')}
            >
              Data Science
            </button>
            <button
              className={`fil-btn ${selectedOptions.includes('Business') ? 'selected' : ''}`}
              onClick={() => handleSelect('Business')}
            >
              Business
            </button>
            <button
              className={`fil-btn ${selectedOptions.includes('Engineering') ? 'selected' : ''}`}
              onClick={() => handleSelect('Engineering')}
            >
              Engineering
            </button>
          </div>
        );
      case 'Countries':
        return (
          <div className="filter-content">
            <button
              className={`fil-btn ${selectedOptions.includes('USA') ? 'selected' : ''}`}
              onClick={() => handleSelect('USA')}
            >
              USA
            </button>
            <button
              className={`fil-btn ${selectedOptions.includes('UK') ? 'selected' : ''}`}
              onClick={() => handleSelect('UK')}
            >
              UK
            </button>
            <button
              className={`fil-btn ${selectedOptions.includes('Canada') ? 'selected' : ''}`}
              onClick={() => handleSelect('Canada')}
            >
              Canada
            </button>
            <button
              className={`fil-btn ${selectedOptions.includes('Australia') ? 'selected' : ''}`}
              onClick={() => handleSelect('Australia')}
            >
              Australia
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="filter-panel">
      <div className="filter-header">
        <h2>Filters</h2>
      </div>
      <div className="filter-container">
        <div className="filter-tabs">
          <button className={`tab ${activeTab === 'Program Level' ? 'active-tab' : ''}`} onClick={() => setActiveTab('Program Level')}>
            Program Level
          </button>
          <button className={`tab ${activeTab === 'Courses' ? 'active-tab' : ''}`} onClick={() => setActiveTab('Courses')}>
            Courses
          </button>
          <button className={`tab ${activeTab === 'Countries' ? 'active-tab' : ''}`} onClick={() => setActiveTab('Countries')}>
            Countries
          </button>
        </div>
        <div className="filter-right-panel">
          {renderContent()}
        </div>
      </div>
      <div className="filter-footer">
        <button className="clear-btn" onClick={clearAll} disabled={selectedOptions.length === 0}>
          Clear All
        </button>
        <button className="apply-btn" disabled={selectedOptions.length === 0}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default Filter;
