import React from 'react'
import "./moreabout.css"
import MoreAbout from './MoreAbout'
import MoreAbout2 from './MoreAbout2'
import MoreAbout3 from './MoreAbout3'

const MainMoreAbout = () => {
  return (
    <div className='moreaboutsection'>
      <MoreAbout/>
      <MoreAbout2/>
      <MoreAbout3/>
    </div>
  )
}

export default MainMoreAbout
