import React from 'react'
import "./moreupcoming.css"
import Moreimg from "../../../assets/More/upcomingeventshero.png";
const MoreUpcoming1 = () => {
  return (
    <>
      <div className='morehero'>
        <div className='moreimg upcoming'>
          {/* Image */}
          <img src={Moreimg} alt='Service' />
          {/* Dark Overlay */}
          <div className='overlay'></div>
        </div>
      </div>
      <div className='upcomingEvents'>
        <h2>Upcoming, <span>Events</span></h2>
        <p className='calander'>Mark Your Calender</p>
        <div>
          <p>Meet with university representatives from top-ranked global institutions and get personalized guidance on your study abroad</p>
          <p>journey. Secure on-the-spot admissions and instant scholarships worth up to <strong>₹40 lakhs!</strong> Choose from over</p>
          <p><strong>80 prestigious universities</strong> across popular study destinations, including </p>
          <p><strong>US, New Zealand, Germany, Ireland, UK, Canada, and Australia.</strong></p>
        </div>
      </div>
    </>
  )
}

export default MoreUpcoming1
