import React, { useState } from 'react';
import './signin.css';
import Signup from './Signup'; // Import Signup component
import EnterOtp from './EnterOtp'; // Import EnterOtp component
import logoImage from '../../assets/sigin/placeholder-image.png';
import Signinimg from "../../assets/sigin/sign-in-img.png";
import mobile from "../../assets/sigin/mobile.png";
import google from "../../assets/sigin/google.png";

const Signin = ({ onClose }) => {
  // Manage modal states for Signup and OTP
  const [showSignup, setShowSignup] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  // Function to show the Signup modal
  const openSignup = () => {
    setShowSignup(true);
  };

  // Function to show the OTP modal
  const openOtpModal = () => {
    setShowOtp(true);
  };

  // Function to hide all modals
  const closeAllModals = () => {
    setShowSignup(false);
    setShowOtp(false);
  };

  return (
    <div>
      {/* If the Signup modal is visible, show the Signup component */}
      {showSignup ? (
        <Signup onClose={closeAllModals} />
      ) : showOtp ? (
        // Show OTP modal when showOtp is true
        <EnterOtp onClose={closeAllModals} />
      ) : (
        <div className="signin-modal">
          <div className="signin-content">
            {/* Close Button (Top Right Corner) */}
            <button className="close-button-sigin" onClick={onClose}>✖</button>

            {/* Placeholder for the Logo/Image */}
            <div className="signin-header">
              <img src={logoImage} alt="Logo" className="signin-logo" />
            </div>

            {/* Sign-in Form */}
            <div className='signfrom-con'>
              <div className='signin-img'>
                <img src={Signinimg} alt='sign-in-img' />
              </div>
              <div className="signin-form">
                <h2 className="signin-title">SIGN IN</h2>

                {/* Input group for phone number */}
                <div className="input-group-signin">
                  <span className="country-code">+91</span>
                  <input type="text" placeholder="Enter Your Number" className="phones-input" />
                </div>

                {/* Request OTP Button */}
                <button className="otp-button" onClick={openOtpModal}>Request OTP</button>

                {/* Divider with 'OR' text */}
                <div className="divider">or</div>

                {/* Email and Google Buttons */}
                <button className="email-button"><img src={mobile} alt='mobile' />Login Using Email</button>
                <button className="google-button"><img src={google} alt='google' />Continue With Google</button>

                {/* Signup Link */}
                <p className="signup-text">
                  Don’t have an account? <span className="signup-link" onClick={openSignup}>SIGN UP</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signin;
