import React, { useState, useEffect } from 'react';
import "./wishlist.css";
import flag from "../../assets/home/flagofindia.png";
import Wishlistimg from "../../assets/wishlist/Wishlist.png";
import OTPSection from "./OTPSection";  // Import the new OTP component

const Wishlist = ({ onClose }) => {
    const [selectedBachelors, setSelectedBachelors] = useState(null);
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [showOTP, setShowOTP] = useState(false);  // New state for OTP view

    useEffect(() => {
        // Disable body scroll when the component mounts
        document.body.style.overflow = "hidden";

        // Re-enable body scroll when the component unmounts
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    const handleBachelorsSelect = (option) => {
        setSelectedBachelors(option);
    };

    const handleSubmit = () => {
        // Show OTP Section on form submit
        setShowOTP(true);
        console.log("Form submitted with details:", { name, mobile, email, selectedBachelors });
    };

    return (
        <div className='wishlist-container'>
            <img src={Wishlistimg} alt='Wishlist' className='wishlistimg' />
            <div className='form-container'>
                <button className="wishclose-btn" onClick={onClose}>&times;</button>
                <div className='cn'>

                    {showOTP ? (
                        <OTPSection onBack={() => setShowOTP(false)} />  // Show OTP form when state is true
                    ) : (
                        <>
                            <div className='form-header'>
                                <p className='form-title'>Discover Details About <br /><span>Study Abroad Opportunities</span></p>
                            </div>

                            <div className='form-body'>
                                <div className='form-group-wish'>
                                    <label className='form-label'>Enter your details <span>*</span></label>
                                    <input
                                        type='text'
                                        placeholder='Name *'
                                        className='form-input-wish'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                <div className='form-group-wish phone-group'>
                                    <div className='phone-left-wish'>
                                        <div className='phone-flag'>
                                            <img src={flag} alt='flag' />
                                            <i className="fa-solid fa-caret-down"></i>
                                        </div>
                                        <p>+91</p>
                                    </div>
                                    <div className='phone-right'>
                                        <input
                                            type='tel'
                                            placeholder='Mobile *'
                                            className='phone-input'
                                            value={mobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className='form-group-wish emailhai'>
                                    <input
                                        type='email'
                                        placeholder='Email *'
                                        className='form-input-wish'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>

                                <div className='form-group-wish bachelors-group'>
                                    <p className='form-label p'>Have you completed your Bachelor's? <span>*</span></p>
                                    <div className='bachelors-options'>
                                        <button
                                            className={`bachelors-btn ${selectedBachelors === 'Yes' ? 'active' : ''}`}
                                            onClick={() => handleBachelorsSelect('Yes')}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            className={`bachelors-btn ${selectedBachelors === 'No' ? 'active' : ''}`}
                                            onClick={() => handleBachelorsSelect('No')}
                                        >
                                            No
                                        </button>
                                    </div>
                                </div>

                                <button className='form-submit' onClick={handleSubmit}>Submit</button>
                            </div>

                        </>
                    )}
                </div>
            </div>
            <div className='form-footer'>
                <p>By submitting this form, you agree to the <span>Terms of Use</span> and <span>Privacy Policy</span></p>
            </div>
        </div>
    );
};

export default Wishlist;
