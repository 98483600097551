import React, { useState } from 'react';
import './signup.css';
import Signin from './Signin'; // Import Signin component here
import logoImage from '../../assets/sigin/placeholder-image.png';
import Signupimg from "../../assets/sigin/sign-in-img.png";
import mobile from "../../assets/sigin/mobile.png";
// import indiaFlag from "../../assets/sigin/india-flag.png";

const Signup = ({ onClose }) => {
  // Manage modal state for switching
  const [showSignin, setShowSignin] = useState(false);

  // Function to show the signin modal
  const openSignin = () => {
    setShowSignin(true);
  };

  // Function to hide the signin modal
  const closeSignin = () => {
    setShowSignin(false);
  };

  return (
    <div>
      {/* If the Signin modal is visible, show the Signin component instead of Signup */}
      {showSignin ? (
        <Signin onClose={closeSignin} />
      ) : (
        <div className="signup-modal">
          <div className="signup-content">
            {/* Close Button (Top Right Corner) */}
            <button className="close-button-signup" onClick={onClose}>✖</button>

            {/* Placeholder for the Logo/Image */}
            <div className="signup-header">
              <img src={logoImage} alt="Logo" className="signup-logo" />
            </div>

            {/* Signup Form */}
            <div className='signform-con'>
              <div className='signup-img'>
                <img src={Signupimg} alt='sign-up-img'/>
              </div>
              <div className="signup-form">
                <h2 className="signup-title">CREATE YOUR ACCOUNT</h2>

                {/* Input fields for Name, Email, and Phone Number */}
                <input type="text" placeholder="Name*" className="texts-input" />
                <input type="email" placeholder="Email*" className="texts-input" />

                {/* Input group for phone number */}
                <div className="input-group">
                {/* <img src={indiaFlag} alt="India" className="flag-icon" /> */}
                  <span className="country-code">+91</span>
                  <input type="text" placeholder="Enter Your Number" className="phone-input" />
                </div>

                {/* Signup Button */}
                <button className="signup-button">Sign Up</button>

                {/* Already have an account link */}
                <p className="signup-text">
                  Already have an account? <span className="signin-link" onClick={openSignin}>SIGN IN</span>
                </p>

                {/* Terms and Conditions */}
                <p className="terms-text">
                  By Signing Up You Agree to Our <span className="terms-link">Terms & Conditions</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
