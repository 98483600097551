import React, { useState } from 'react';
import "./moresection2.css"
import Dwelling from '../../../assets/services/Dwelling.png';
import discussion from '../../../assets/More/upcomingevents.png';
import education from '../../../assets/More/Careers.png';
import Contact from "../../../assets/More/Contact.png"
import stamp from '../../../assets/services/stamp.png';
import { NavLink } from 'react-router-dom';
const MoreSection2 = () => {
     // State to control the animation class
     const [hovered, setHovered] = useState(null);
  return (
    <div className='servicesection2'>
            <div className='sersec2-head'>
                <h1>Expand Your, <span>Vision</span></h1>
                <p>More Study Abroad Essentials</p>
            </div>
            <div className='services-icons'>
                {/* Service Items with hover and mouse leave events */}
                {[{ src: discussion, title: 'Upcoming' }, { src: education, title: 'Careers', navsrc:"/career" }, { src: stamp, title: 'University' }, { src: Contact, title: 'Contacts' }, { src: Dwelling, title: 'About us', navsrc:"/morecareer" }].map((item, index) => (
                    <NavLink to={item.navsrc} className='service-item' key={index}>
                        <div
                            className={`icon-border ${hovered === index ? 'rotate-clockwise' : hovered === `leave-${index}` ? 'rotate-anticlockwise' : ''}`}
                            onMouseEnter={() => setHovered(index)}
                            onMouseLeave={() => setHovered(`leave-${index}`)}
                        >
                        </div>
                        <div className='icon-image-wrapper'>
                            <img src={item.src} alt={item.title} className='service-icon' />
                        </div>
                        <p className='service-title'>{item.title}</p>
                    </NavLink>
                ))}
            </div>
        </div>
  )
}

export default MoreSection2
