import React from 'react';
import "./newcarrer.css";
import CareerGroup from "../../../assets/Career/CareerGroup.png"; // Generous Salary Packages icon
import Medal from "../../../assets/Career/Medal.png"; // Foster Achievement icon
import Workenvironment from "../../../assets/Career/Workenvironment.png"; // Inclusive Culture of Belonging icon
import healthsupport from "../../../assets/Career/healthsupport.png"; // Health Support icon

const Career2page = () => {
    return (
        <div className="career-container">
            <h2 className="career-title">
                FBT Employees Thrive with<br /> <span className="highlight">"Amazing Benefits"</span>
            </h2>

            <div className="career-cards">
                {/* Generous Salary Packages */}
                <div className="career-card">
                    <div className='careercard-inside'>
                        <img src={CareerGroup} alt="Generous Salary Packages" className="career-icon" />
                        <div>
                            <h3 className="career-card-title">Generous Salary Packages</h3>
                            <p className="career-card-subtitle">Fair & Equitable Pay</p>
                        </div>
                    </div>
                    <p className="career-card-description">
                        We make sure your skills, experience, and passion are rewarded with industry-leading salaries.
                        At FBT, we provide our employees with the financial stability they need to thrive and achieve success.
                    </p>
                </div>

                {/* Health Support */}
                <div className="career-card">
                    <div className='careercard-inside'>
                        <img src={healthsupport} alt="Health Support" className="career-icon" />
                        <div>
                            <h3 className="career-card-title">Health Support</h3>
                            <p className="career-card-subtitle">Employee Health is a Cornerstone of Performance</p>
                        </div>
                    </div>
                    <p className="career-card-description">
                        We recognize the importance of your health and are dedicated to supporting you throughout your journey.
                        Our approach includes offering well-being initiatives, health insurance, and access to resources you need to maintain a harmonious lifestyle.
                    </p>
                </div>

                {/* Inclusive Culture of Belonging */}
                <div className="career-card">
                    <div className='careercard-inside'>
                        <img src={Workenvironment} alt="Inclusive Culture of Belonging" className="career-icon" />
                        <div>
                            <h3 className="career-card-title">Inclusive Culture of Belonging</h3>
                            <p className="career-card-subtitle">Building Community Through Acceptance</p>
                        </div>
                    </div>
                    <p className="career-card-description">
                        At FBT, we are committed to creating a vibrant work environment for every employee.
                        By cultivating a safe and productive workspace where diverse voices are valued, we empower employees to contribute their best work and build lasting connections.
                    </p>
                </div>

                {/* Foster Achievement */}
                <div className="career-card">
                    <div className='careercard-inside'>
                        <img src={Medal} alt="Foster Achievement" className="career-icon" />
                        <div>
                            <h3 className="career-card-title">Foster Achievement</h3>
                            <p className="career-card-subtitle">Your Success is Fundamental to Our Vision!</p>
                        </div>
                    </div>

                    <p className="career-card-description">
                        Our comprehensive programs are focused on equipping individuals with the skills and knowledge to become future leaders.
                        We believe in nurturing talent and fostering a positive, growth-oriented environment, creating a culture that inspires growth and innovation.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Career2page;
