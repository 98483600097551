import React, { useState } from "react";
import "./exam.css"; // Import CSS for styling

const Exam = () => {
    // State to manage which exam is selected in the left menu
    const [selectedExam, setSelectedExam] = useState("IELTS");

    // Data structure for exams and their respective sections
    const examData = {
        IELTS: {
            "IELTS Exam": [
                "IELTS Exam",
                "IELTS Syllabus",
                "IELTS Preparation",
                "IELTS Eligibility",
                "IELTS Test Format",
                "IELTS Band Descriptors",
                "IELTS Speaking Test",
                "IELTS Writing Task 1",
                "IELTS Score Validity",
                "IELTS Cue Card",
            ],
            "IELTS Reading Answers Sample": [
                "Animal Camouflage",
                "Types of Societies",
                "Australian Comic",
                "A Spark A Flink",
                "Emigration To The Us",
                "The History of Salt",
                "Zoo Conservation Program",
                "The Robots Are Coming",
                "The Development of Plastic",
            ],
            "IELTS Essay Sample": [
                "Best Education System",
                "Global Warming Essay",
                "Map Essay",
                "IELTS Opinion Essay",
                "Agree or Disagree Essay",
                "Problem Solution Essay",
                "Essay on Space Exploration",
                "Essay on Historical Places",
                "Tourism Essay",
                "Global Warming Essay",
            ],
        },
        GRE: {
            "GRE Exam": ["GRE Exam", "GRE Syllabus", "GRE Preparation", "GRE Eligibility", "Sections in GRE Exam", "GRE Exam Benefits", "GRE Exam Results", "Cut-Off for US Universities", "GRE Exam Fee", "Send Scores to Universities"],
            "GRE Exam Study Material": ["GRE Verbal Preparation", "Stanford University Cut-Off" ,"Harvard University GRE Cut-Off", "GRE Quantitative Reasoning", "GRE Verbal Reasoning ", "GRE Reading Comprehension", "Prepare for GRE in 2-Months"],
            "Free GRE E-Books": ["GRE Preparation Guide (free PDF)", "GRE Syllabus (free PDF)"],
        },
        GMAT: {
            "GMAT Exam": ["GMAT Exam", "GMAT Syllabus", "GMAT Exam Fee", "GMAT Eligibility", "GMAT Purpose", "GMAT Sections", "GMAT Exam Date", "GMAT Registration", "GMAT Score"],
            "GMAT Exam Study Material": ["How to Prepare for GMAT", "GMAT Score Validity", "GMAT Preparation Books", "GMAT Exam Duration", "GMAT Score for Harvard", "AWA GMAT", "GMAT Retake Strategy", "GMAT Reading Comprehension"],
            "Free GRE E-Books": ["GMAT Guide PDF", "Download GMAT Syllabus PDF"],
        },
        SAT: {
            "SAT Exam": ["SAT Exam", "SAT Syllabus", "SAT Exam Fee", "SAT Eligibility","SAT Purpose", "SAT Sections", "SAT Exam Date", "SAT Registration", "SAT Format"],
            "SAT Exam Study Material": ["How to Prepare for SAT", "SAT Score Validity", "SAT Preparation Books", "SAT Exam Duration", "SAT Vocabulary List", "SAT Questions", "SAT Study Tips"],
            "FREE SAT E-BOOKS": ["Official SAT Study Guide", "Download SAT Syllabus PDF"],
        },
        ACT: {
            "ACT Exam": ["ACT Exam", "ACT Syllabus", "ACT Exam Fee", "ACT Eligibility", "ACT Purpose", "ACT Sections", "ACT Exam Date", "ACT Registration", "ACT Format"],
            "ACT Exam Study Material": ["How to Prepare for ACT", "ACT Score Validity", "ACT Preparation Books", "ACT Exam Duration", "ACT Vocabulary List", "ACT Questions", "ACT Study Tips"],
            "Free ACT E-books": ["Official ACT Study Guide", "Download ACT Syllabus PDF"],
        },
        Other: {
            "PTE Exam": ["PTE Exam", "PTE Syllabus", "PTE Exam Fee", "PTE Eligibility", "PTE Pattern", "PTE Sections", "PTE Registration", "PTE Exam Date "],
            "DUOLINGO Exam": ["Duolingo Exam", "Duolingo Text Eligibility", "Duolingo Exam Pattern", "Duolingo Exam Fee", "Duolingo Test Validity", "Duolingo Syllabus", "Duolingo Preparation"],
            "OTHER Exam": ["NEET Exam", "MCAT Exam", "OET Exam", "CET Exam", "CSAB Exam", "PLAB Exam", "LSAT Exam", "TOEIC Exam","USMLE Exam"],
        },
    };

    return (
        <div className="exams-container">
            {/* Left Menu */}
            <div className="exams-left-menu">
                {Object.keys(examData).map((exam, index) => (
                    <p
                        key={exam}
                        className={`${selectedExam === exam ? "active" : ""} ${index === 0 ? "first-left-item" : ""}`}
                        onClick={() => setSelectedExam(exam)}
                    >
                        {exam}
                    </p>
                ))}
            </div>

            {/* Right Content */}
            <div className="exams-right-content">
                <div className="exam-right-con">
                    {Object.entries(examData[selectedExam]).map(([section, topics]) => (
                        <div key={section} className="exam-section">
                            <h4>{section}</h4>
                                <ul>
                                    {topics.map((topic, index) => (
                                        <li key={topic} className={index === 0 ? "highlighted-topic" : ""}>
                                            {topic}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                    ))}
                </div>
                <button className="understand-button">Understand {selectedExam} Better</button>
            </div>
        </div>
    );
};

export default Exam;
