import React from 'react';
import "./moreupcoming.css";
import greentick from "../../../assets/More/Group.png"; // Import the green tick image

const MoreUpcoming3 = () => {
  return (
    <div className="moreupcoming-container">
      <h3 className="moreupcoming-title">Don't Miss the Chance To</h3>
      <ul className="moreupcoming-list">
        <li>
          <img src={greentick} alt="Green Tick" className="moreupcoming-icon" />
          Secure On-the-Spot Admissions
        </li>
        <li>
          <img src={greentick} alt="Green Tick" className="moreupcoming-icon" />
          Experience 1:1 Profile Evaluation & Counseling
        </li>
        <li>
          <img src={greentick} alt="Green Tick" className="moreupcoming-icon" />
          Seize GRE/GMAT Waiver
        </li>
        <li>
          <img src={greentick} alt="Green Tick" className="moreupcoming-icon" />
          Avail Exclusive Scholarships
        </li>
        <li>
          <img src={greentick} alt="Green Tick" className="moreupcoming-icon" />
          Get IELTS, Visa & Loan Guidance
        </li>
      </ul>
    </div>
  );
};

export default MoreUpcoming3;
