import React from "react";
import "./countries.css";

// Placeholder images (use appropriate paths for the flags)
import ukFlag from '../../assets/Countries/uk.png';
import usaFlag from '../../assets/Countries/usa.png';
import canadaFlag from '../../assets/Countries/canada.png';
import australiaFlag from '../../assets/Countries/australia.png';
import newZealandFlag from '../../assets/Countries/newzealand.png';
import germanyFlag from '../../assets/Countries/germany.png';
import irelandFlag from '../../assets/Countries/ireland.png';
import othersFlag from '../../assets/Countries/others.png';

const Countries = ({ onCountrySelect }) => {
    return (
        <div className="countries-container">
            <div className="country-row">
                <div className="country" onClick={onCountrySelect}>
                    <img src={ukFlag} alt="UK Flag" />
                    <p>UK</p>
                </div>
                <div className="country" onClick={onCountrySelect}>
                    <img src={irelandFlag} alt="Ireland Flag" />
                    <p>Ireland</p>
                </div>

                <div className="country" onClick={onCountrySelect}>
                    <img src={canadaFlag} alt="Canada Flag" />
                    <p>Canada</p>
                </div>
            </div>
            <div className="country-row second">
                <div className="country" onClick={onCountrySelect}>
                    <img src={usaFlag} alt="USA Flag" />
                    <p>USA</p>
                </div>
                <div className="country" onClick={onCountrySelect}>
                    <img src={australiaFlag} alt="Australia Flag" />
                    <p>Australia</p>
                </div>
                <div className="country" onClick={onCountrySelect}>
                    <img src={germanyFlag} alt="Germany Flag" />
                    <p>Germany</p>
                </div>
            </div>
            <div className="country-row-last">
                <div className="country" onClick={onCountrySelect}>
                    <img className="newz" src={newZealandFlag} alt="New Zealand Flag" />
                    <p>New Zealand</p>
                </div>
                <div className="country other" onClick={onCountrySelect}>
                    <img className="otherimg" src={othersFlag} alt="Others Flag" />
                    <p>Others</p>
                </div>
            </div>
        </div>
    );
};

export default Countries;
