import React, { useState } from 'react'
import "./blog.css"
import whitelogo from "../../assets/blog/FBTWhite.png"
import blog1 from "../../assets/blog/blog1.png"
import tablecontent from "../../assets/blog/Details1.png"
import Like from "../../assets/blog/Component23.png"
const Blog = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const questions = [
    "Step 1: Research and Choose Your Destination",
    "Step 2: Select Suitable Study Program and Institution",
    "Step 3: Check Admission Requirements and Eligibility Criteria",
    "Step 4: Arrange Finances and Explore Funding Options",
    "Step 5: Apply for Student Visa and Fulfill Immigration Requirements",
    "Step 6: Arrange Accommodation and Prepare for Arrival",
    "Step 7: Embrace Study Abroad Experience and Stay Engaged",
    "Conclusion"
  ];

  const answers = [
    "The first step in studying abroad is to research and choose your destination wisely. Consider the following factors while selecting your study destination Quality of education",
    "Once you've chosen your destination, the next step is to select a suitable study program and institution that aligns with your academic interests, career goals, and personal preferences. Explore the range of programs offered, including undergraduate, graduate, and exchange programs, and consider factors such as program curriculum, faculty expertise, research opportunities, campus facilities, and student support services. Attend virtual or in-person information sessions, webinars, and campus tours to get a firsthand experience of the institution and its offerings.",
    "Before applying to study abroad, it's essential to check the admission requirements and eligibility criteria for your chosen study program and institution. This may include academic qualifications, standardized test scores (such as SAT, ACT, GRE, or GMAT), English language proficiency scores (such as IELTS, TOEFL, PTE, or Duolingo), letters of recommendation (LORs), statement of purpose (SOP), resume or curriculum vitae (CV), and any additional application materials specified by the institution. Be sure to meet all deadlines and submit your application well in advance to allow for processing time.",
    "Studying abroad can be a significant financial investment, so it's essential to arrange your finances and explore funding options in advance. Calculate the total cost of tuition, accommodation, living expenses, travel, insurance, and other miscellaneous expenses, and develop a realistic budget to manage your finances effectively. Explore scholarship opportunities, grants, fellowships, and financial aid programs offered by universities, government agencies, non-profit organizations, and private foundations to offset the cost of studying abroad. Consider part-time work or internships to supplement your income and gain valuable work experience.",
    "Once you've been accepted into your chosen study program and institution, the next step is to apply for a student visa and fulfill immigration requirements to study abroad legally. Research the visa application process and requirements for your chosen destination, including visa types, application forms, documentation, bio-metrics, visa fees, and processing times. Prepare all required documents, such as a valid passport, acceptance letter, financial statements, health insurance, proof of accommodation, and visa application forms. Submit your application to the appropriate embassy or consulate. Attend any required interviews or appointments and follow up on your application status until you receive your visa.",
    "With your visa in hand, it's time to arrange accommodation and prepare for your arrival in your chosen study destination. Research accommodation options, such as university dormitories, private rentals, homestays, or shared apartments, and secure housing that meets your preferences, budget, and location preferences.  Familiarize yourself with local transportation options, healthcare services, banking facilities, emergency contacts, and other essential information to ensure a smooth transition to your new environment. Pack necessary belongings, travel documents, and essentials for your journey, and stay updated on any travel restrictions or health guidelines due to the ongoing COVID-19 pandemic.",
    "Finally, embrace the study abroad experience with an open mind, curiosity, and enthusiasm for learning and growth. Immerse yourself in the local culture, language, customs, and traditions, and seize every opportunity to explore new places, meet diverse people, and engage in meaningful experiences both inside and outside the classroom. Stay connected with family and friends back home, while also building new connections and friendships with fellow students, professors, and members of the community. Take advantage of academic resources, extracurricular activities, student clubs, and cultural events to enhance your educational experience and make the most of your time abroad.",
    "In conclusion, studying abroad is a trans-formative journey that requires careful planning, preparation, and perseverance. By following this step-by-step guide, aspiring international students can navigate the complexities of studying abroad with confidence and embark on a rewarding educational adventure that will shape their future success and global perspective. Whether you're pursuing an undergraduate, graduate, or exchange program, studying abroad offers invaluable opportunities for personal, academic, and professional growth that will last a lifetime."
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className='yethobloghai'>
      <div className='leftpart'>
        <p className='upperpara'><span>Home /</span> A Step-by-Step Approach to Studying Abroad: </p>
        <div class="blog-header">
          <h1>A Step-by-Step Approach to Studying Abroad :</h1>
          <p class="blog-date">Update on 10 October 2024</p>
        </div>
        <div class="blog-author">
          <img src={whitelogo} alt="Author Image" class="author-image" />
          <div class="author-details">
            <p className='kushal'>Kushal Kumar</p>
            <p class="author-role">Sr. Graphic / UI Designer</p>
          </div>
        </div>
        <div class="blog-banner">
          <div class="banner-content">
            <img src={blog1} alt='blog1' className='blogimg' />
          </div>
        </div>
        <p class="blog-description">
          Studying abroad is a goal for many students worldwide, offering unique opportunities for personal development, academic advancement, and cultural immersion. However, without the right guidance, the process of pursuing international education can feel overwhelming.
        </p>
        <p className='anotherdes'>
          In this comprehensive guide, we'll outline a step-by-step procedure to help aspiring international students fulfill their dream of studying abroad.
        </p>
        <div className='tablecontent'>
          <div className='tablecontent-left'>
            <img src={tablecontent} alt='tablecontent' />
            <p>Table of Contents</p>
          </div>
          <button>Expand</button>
        </div>

        <div className='tablelist-con'>
          <div className='tablelist'>
            {questions.map((question, index) => (
              <div key={index} className='tableitem'>
                <div className='table-question' onClick={() => toggleAccordion(index)}>
                  <span>{question}</span>
                  <span className={`table-icon ${activeIndex === index ? 'active' : ''}`}>+</span>
                </div>
                {activeIndex === index && (
                  <div className={`table-answer ${activeIndex === index ? 'show' : ''}`}>
                    <p>{answers[index]}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className='disclaimer'>
          <p>Disclaimer: "This content was generated using AI algorithms. While we strive for accuracy, errors or inconsistencies may occur."</p>
        </div>
        <div className='disclamer-inside'>
          <p>Did you find this article helpful?</p>
          <img src={Like} alt='like' />
        </div>
        <div className='lastcard'>
          <div className='card'>
            <div className='card-header'>
              <img src={whitelogo} alt="Author Logo" className="author-logo" />
              <div className='author-info'>
                <p className='author-name'>Kushal Kumar</p>
                <p className='author-role'>Sr. Graphic / UI Designer</p>
              </div>
            </div>
            <div className='card-body'>
              <p className='author-description'>
                Kushal Kumar is a skilled and passion-driven professional working as a Senior Graphic/UI Designer. With around 6 years of experience, he specializes in creating visually compelling and user-friendly designs across websites...
              </p>
              <a href='/' className='read-more'>...Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div className='rightpart'>
        <div className='right-section'>
          <div className='box'>
            <h3>Exams to Study Abroad</h3>
            <ul>
              <li>IELTS Exam</li>
              <li>GRE Exam</li>
              <li>GMAT Exam</li>
              <li>TOEFL Exam</li>
              <li>SAT Exam</li>
            </ul>
          </div>

          <div className='box'>
            <h3>Best Countries for Studying Abroad</h3>
            <ul>
              <li>UK</li>
              <li>USA</li>
              <li>Canada</li>
              <li>Australia</li>
              <li>Germany</li>
              <li>Ireland</li>
              <li>New Zealand</li>
            </ul>
          </div>
          <div className='newbox'>
            <div className='inside-newbox'>
              <div className="study-abroad-form">
                <h3>Complimentary Study Abroad Guidance</h3>
                <p className='kuchthohai'>Confirm Your Contact to Receive a Free Session with Experts !</p>
                <form>
                  <label className='alghai'>Phone number</label>
                  <div className="input-group">
                    <span className="flag">🇮🇳 +91</span>
                    <input type="text" placeholder="Enter your phone number" />
                  </div>

                  <label className='sbsbealg'>Have you completed your Bachelor's?</label>
                  <div className="radio-group">
                    <button>yes</button>
                    <button className='novala'>No</button>
                  </div>
                  <button className='submithaiye' type="submit">Submit</button>
                  <p className='ekdumlasthai'>By submitting this form, you agree to the <a href="#">Terms of Use</a> and <a href="#">Privacy Policy</a>.</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog
