import React, { useState, useEffect } from 'react';
import './enterOtp.css'; // Import the CSS file for styling
import logoImage from '../../assets/sigin/placeholder-image.png'; // Replace with your logo path
import EditPhone from './Editphone'; // Import the EditPhone component

const EnterOtp = ({ onClose }) => {
  const [resendTimer, setResendTimer] = useState(30);
  const [showEditPhone, setShowEditPhone] = useState(false); // State for EditPhone modal

  // Handle the countdown timer for Resend OTP
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => {
        setResendTimer(resendTimer - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  // Function to reset the timer when Resend OTP is clicked
  const handleResendOtp = () => {
    setResendTimer(30);
    // Additional code to resend the OTP can be added here
  };

  // Function to open the EditPhone modal
  const openEditPhoneModal = () => {
    setShowEditPhone(true);
  };

  // Function to close the EditPhone modal
  const closeEditPhoneModal = () => {
    setShowEditPhone(false);
  };

  return (
    <div>
      {/* Display EnterOtp Modal */}
      <div className="otp-modal">
        <div className="otp-container">
          <button className="close-button" onClick={onClose}>✖</button>
          <div className="otp-logo">
            <img src={logoImage} alt="Company Logo" />
          </div>
          <h2 className="otp-title">Verify your phone number</h2>
          <p className="otp-description">An OTP has been sent to your phone number</p>
          
          {/* Phone Number with Edit Button */}
          <p className="otp-number">
            +91-9027639682<br/> <span className="otp-edit" onClick={openEditPhoneModal}>Edit</span>
          </p>

          {/* OTP Input Boxes */}
          <div className="otp-input-container">
            <input type="text" maxLength="1" className="otp-input" placeholder='*' />
            <input type="text" maxLength="1" className="otp-input" placeholder='*'/>
            <input type="text" maxLength="1" className="otp-input" placeholder='*'/>
            <input type="text" maxLength="1" className="otp-input" placeholder='*'/>
          </div>

          {/* Timer and Resend Button */}
          <div className="otp-timer">
            {resendTimer > 0 ? (
              <span>Resend OTP in {resendTimer} seconds</span>
            ) : (
              <span className="resend-otp" onClick={handleResendOtp}>Resend OTP</span>
            )}
          </div>
        </div>
      </div>

      {/* Display EditPhone Modal when showEditPhone is true */}
      {showEditPhone && <EditPhone onClose={closeEditPhoneModal} />}
    </div>
  );
};

export default EnterOtp;
