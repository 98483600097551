import React, { useEffect} from 'react'
import { NavLink } from 'react-router-dom'
import "./sidemodal.css"
const SideModal = ({ setIsModalOpen }) => {
    // Use useEffect to handle disabling/enabling body scrolling
    useEffect(() => {
        document.body.style.overflow = "hidden"; // Disable body scroll when component mounts

        return () => {
            document.body.style.overflow = "auto"; // Re-enable body scroll when component unmounts
        };
    }, []);
    return (
        <div className='sidemodal'>
            <div className='insidesidemodal' onClick={() => setIsModalOpen(false)}>
                <NavLink to="/countries">Countries</NavLink>
                <NavLink to="/">Exams</NavLink>
                <NavLink to="/services">Services</NavLink>
                <NavLink to="/more">More</NavLink>
                <NavLink to="/"> Sign In</NavLink>
            </div>
        </div>
    )
}

export default SideModal
