import React, { useState } from 'react';
import './ExploreCourses.css';
import Exploreimage from "../../assets/Cources/exploreimage.png"

const coursesData = [
  {
    courcename: "MBA (90 ECTS)",
    universityName: 'Harvard University',
    countryName: 'USA',
    postStudyVisa: 'Up to 36 Months',
    onCampusFee: 'USD 15,052 (Indicative)',
    onlineFee: 'USD 5,000',
    duration: { online: '8 Months', onCampus: '1 Year' },
    image: Exploreimage, // Replace with actual image path
  },
  {
    courcename: "MBA(90 ECTS)",
    universityName: 'Stanford University',
    countryName: 'USA',
    postStudyVisa: 'Up to 36 Months',
    onCampusFee: 'USD 14,052 (Indicative)',
    onlineFee: 'USD 8,052',
    duration: { online: '8 Months', onCampus: '1 Year' },
    image: Exploreimage, // Replace with actual image path
  },
  {
    courcename: "MBA(90 ECTS)",
    universityName: 'MIT Sloan School',
    countryName: 'USA',
    postStudyVisa: 'Up to 36 Months',
    onCampusFee: 'USD 18,052 (Indicative)',
    onlineFee: 'USD 7,000',
    duration: { online: '8 Months', onCampus: '1 Year' },
    image: Exploreimage, // Replace with actual image path
  },
  {
    courcename: "Executive MBA (90 ECTS)",
    universityName: 'University of British',
    countryName: 'Canada',
    postStudyVisa: 'Up to 36 Months',
    onCampusFee: 'USD 17,052 (Indicative)',
    onlineFee: 'INR 4,50,000',
    duration: { online: '8 Months', onCampus: '1 Year' },
    image: Exploreimage, // Replace with actual image path
  },
  {
    courcename: "MBA in Finance (90 ECTS)",
    universityName: 'Mannheim Business School',
    countryName: 'Germany',
    postStudyVisa: 'Up to 36 Months',
    onCampusFee: 'USD 14,052 (Indicative)',
    onlineFee: 'USD 3,052',
    duration: { online: '8 Months', onCampus: '1 Year' },
    image: Exploreimage, // Replace with actual image path
  },
  {
    courcename: "MBA in Marketing (90 ECTS)",
    universityName: 'Duke University (Fuqua)',
    countryName: 'USA',
    postStudyVisa: 'Up to 36 Months',
    onCampusFee: 'USD 15,052 (Indicative)',
    onlineFee: 'USD 5,052',
    duration: { online: '8 Months', onCampus: '1 Year' },
    image: Exploreimage, // Replace with actual image path
  },
  {
    courcename: "Project Management(90 ECTS)",
    universityName: 'University of Melbourne',
    countryName: 'Australia',
    postStudyVisa: 'Up to 36 Months',
    onCampusFee: 'USD 19,052 (Indicative)',
    onlineFee: 'USD 9,052',
    duration: { online: '8 Months', onCampus: '1 Year' },
    image: Exploreimage, // Replace with actual image path
  },
  {
    courcename: "Artificial Intelligence (90 ECTS)",
    universityName: 'University of Western Australia',
    countryName: 'Australia',
    postStudyVisa: 'Up to 36 Months',
    onCampusFee: 'USD 15,052 (Indicative)',
    onlineFee: 'USD 7,052 ',
    duration: { online: '8 Months', onCampus: '1 Year' },
    image: Exploreimage, // Replace with actual image path
  },
  {
    courcename: "MS in Business Analytics",
    universityName: 'Oxford University',
    countryName: 'England',
    postStudyVisa: 'Up to 36 Months',
    onCampusFee: 'USD 17,052 (Indicative)',
    onlineFee: 'INR 4,50,000',
    duration: { online: '8 Months', onCampus: '1 Year' },
    image: Exploreimage, // Replace with actual image path
  },

];

const ExploreCourses = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const coursesPerPage = 5;

  // Pagination Logic
  const indexOfLastCourse = currentPage * coursesPerPage;
  const indexOfFirstCourse = indexOfLastCourse - coursesPerPage;
  const currentCourses = coursesData.slice(indexOfFirstCourse, indexOfLastCourse);

  const totalPages = Math.ceil(coursesData.length / coursesPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="explore-container">
      <h2 className="header-title">Scale-Up <span>Programs</span></h2>

      {/* Filter Section */}
      <div>
        <p className='preference'>Customize Preferences</p>
        <div className="filter-section">
          <div className="filter-group">
            <p>Choose your Study Stage</p>
            <button className="filter-btn ">Bachelors</button>
            <button className="filter-btn active">Masters</button>
          </div>

          <div className="filter-group">
            <p>Choose your Country</p>
            <button className="filter-btn active">UK</button>
            <button className="filter-btn">USA</button>
            <button className="filter-btn">Canada</button>
            <button className="filter-btn">Australia</button>
            <button className="filter-btn">Germany</button>
            <button className="filter-btn">Ireland</button>
            <button className="filter-btn">New Zealand</button>
          </div>

          <div className="filter-group">
            <p>Choose your Courses</p>
            <button className="filter-btn active">Business Analytics</button>
            <button className="filter-btn">MBA</button>
            <button className="filter-btn">Computer Science</button>
            <button className="filter-btn">Information Technology</button>
            <button className="filter-btn">Cyber Security</button>

          </div>
        </div>
      </div>

      {/* Courses Section */}
      <div className="cour-container">
        {currentCourses.map((course, index) => (
          <div key={index} className="cou-card">
            <div className="cou-image-container">
              <img src={course.image} alt={`${course.universityName} image`} className="cou-image" />
            </div>
            <div className="cour-details">
              <div className='onegroup'>
                <p>University Name<span>{course.universityName}</span></p>
                <p className='insideonegroup'>Country Name<span>{course.countryName}</span></p>
                <p>Post Study Work VISA Eligiblity<span>{course.postStudyVisa}</span></p>
              </div>
              <div className='secondgroup'>
                <p>On-Campus Tuition Fee<span>{course.onCampusFee}</span></p>
                <p>Online Tuition Fee<span>{course.onlineFee}</span></p>
              </div>
              <div className='thirdgroup'>
                <p className='name'>{course.courcename}</p>
                <p><strong>Online Duration -</strong> {course.duration.online}</p>
                <p><strong>On-Campus Duration -</strong> {course.duration.onCampus}</p>
              </div>
              <div className="btn-group">
                <button className="btn-eligibility">Check Eligibility</button>
                <button className="btn-more">Know More</button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className='phone-cour-container'>
        {currentCourses.map((course, index) => (
          <div key={index} className='phone-cou-card'>
            <div className='phone-image-card'>
              <img src={course.image}  alt='Exploreimage' />
            </div>
            <div className='phone-down'>
              <p className='phone-ms'>MS in Business Analytics</p>
              <p className='phone-uni'><i>{course.universityName}</i></p>
              <p className='phone-con'><strong>Country:</strong> <span>{course.countryName}</span></p>
              <div className='phone-duration'>
                <p><strong>Duration</strong></p>
                <div>
                  <p>Online: <br />{course.duration.online}</p>
                  <p>On-Campus: <br /> {course.duration.onCampus}</p>
                </div>
              </div>
              <div className='phone-fee'>
                <p><strong>Fees</strong></p>
                <div>
                  <p>Online: <br />{course.onlineFee}</p>
                  <p>On-Campus: <br />{course.onCampusFee}</p>
                </div>
              </div>
              <div className='phone-visa'>
                <p><strong>Post Study Work VISA Eligiblity</strong></p>
                <p>{course.postStudyVisa}</p>
              </div>
              <div className="phone-btn-group">
                <button className="btn-eligibility">Check Eligibility</button>
                <button className="btn-more">Know More</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Pagination */}
      {/* Pagination */}
      <div className="pagination">
        <button
          className={`page-arrow ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => handleClick(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => handleClick(i + 1)}
            className={currentPage === i + 1 ? 'active' : ''}
          >
            {i + 1}
          </button>
        ))}
        <button
          className={`page-arrow ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={() => handleClick(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ExploreCourses;
