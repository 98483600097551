import React from "react";
import "./moreabout.css";
import mission from "../../../assets/More/mission.jpg"

const MoreAbout3 = () => {
    return (
        <div className="moreabout-container">
            <div className="company-introduction">
                <h2>
                    Company <span className="highlight">Introduction</span>
                </h2>
                <p>
                    Are you ready to embark on a transformative journey of self-discovery
                    and empowerment with FBT Study Abroad?
                </p>
                <p>
                    We are dedicated to making your dream of studying abroad a reality,
                    and now you can do it without the limitations of traditional
                    classrooms. Study from the comfort of your home while connecting
                    virtually with top mentors and education professionals from around the
                    globe. With our diverse course offerings, you can unlock international
                    job opportunities by studying your first year online and completing
                    your second year on-campus at prestigious international universities.
                </p>
                <p>
                    Our education experts, with over a decade of experience, will guide
                    you through every step of the process—from visa applications and
                    documentation to crafting your SOP—ensuring a smooth transition until
                    you step onto your chosen campus.
                </p>
            </div>

            <div className="mission-vision-section">
                <img src={mission} alt="mission"/>
            </div>

            <div className="statistics-section">
                <div className="kuchthohai">
                    <div className="firsehai">
                        <div className="stat-box">
                            <h3>50%</h3>
                            <p>Standard Pay Increase</p>
                        </div>
                        <div className="stat-box">
                            <h3>10 Million+</h3>
                            <p>Students Rely on Our Expertise</p>
                        </div>
                        <div className="stat-box">
                            <h3>85%</h3>
                            <p>Thrilled with the Outcome</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MoreAbout3;
