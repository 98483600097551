import React from 'react';
import './editPhone.css'; // Import CSS file for styling
import logoImage from '../../assets/sigin/placeholder-image.png'; // Replace with your logo path

const Editphone = ({ onClose }) => {
  return (
    <div className="edit-phone-modal">
      <div className="edit-phone-container">
        {/* Close Button */}
        <button className="close-button" onClick={onClose}>✖</button>

        {/* Logo */}
        <div className="edit-phone-logo">
          <img src={logoImage} alt="Company Logo" />
        </div>

        {/* Header Text */}
        <h2 className="edit-phone-title">Edit your phone number</h2>

        {/* Input Field for Mobile Number */}
        <div className="inp-group">
          <input type="text" placeholder="Mobile number" className="ph-input" />
        </div>

        {/* Request OTP Button */}
        <button className="request-otp-button" onClick={onClose}>Request OTP</button>
      </div>
    </div>
  );
};

export default Editphone;
