import React, { useState } from 'react';
import "./servicesection3.css";
import serviceimageframe from "../../../assets/services/serviceimageframe.png";
import serviceimageframe2 from"../../../assets/services/serviceimageframe2.png";
import Serviceform from "../../Services/Serviceform/Serviceform"; 

const ServiceSection3 = () => {
  // State to handle modal visibility
  const [isserviceVisible, setIsServiceVisible] = useState(false);

  const handleServiceClick = () => {
    setIsServiceVisible(!isserviceVisible)
  }


  return (
    <>
      <div className='servicesection3'>
        <div className='service2-image-wrapper'>
          <img src={serviceimageframe} alt="Service Frame" className='service-image' />
        </div>
        <div className='service2-content'>
          <h2 className='service2-title'>Counseling</h2>
          <p className='service2-subtitle'>Finding Clarity Through Connection</p>
          <ul className='service2-list'>
            <li>Personalized Counseling</li>
            <li>Language Support</li>
            <li>Transition Support</li>
            <li>Crisis Management</li>
            <li>Post-Arrival Support</li>
          </ul>
          <button className='service2-button'>Know More</button>
        </div>
      </div>

      <div className='dusrahai'>
        <div className='service2-0-content'>
          <h2 className='service2-title'>Scholarship</h2>
          <p className='service2-subtitle'>Funding Your International Education</p>
          <ul className='service2-list'>
            <li>Types of Scholarship Available</li>
            <li>Eligibility Criteria</li>
            <li>Funding Amounts & Coverage</li>
            <li>Impact of Scholarship</li>
            <li>Scholarship for Higher Education</li>
          </ul>
          <button className='service2-button' >Know More</button>
        </div>
        <div className='service2-0-image-wrapper'>
          <img src={serviceimageframe2} alt="Service Frame" className='service-image' />
        </div>
      </div>

      <div className='servicesection3'>
        <div className='service2-image-wrapper'>
          <img src={serviceimageframe} alt="Service Frame" className='service-image' />
        </div>
        <div className='service2-content'>
          <h2 className='service2-title'>VISA</h2>
          <p className='service2-subtitle'>Navigating your VISA Journey</p>
          <ul className='service2-list'>
            <li>VISA Application Process</li>
            <li>VISA Fee & Payment Method</li>
            <li>Resources for VISA Support</li>
            <li>Impact of VISA Status</li>
            <li>Re-visiting VISA Conditions</li>
          </ul>
          <button className='service2-button' >Know More</button>
        </div>
      </div>

      <div className='dusrahai'>
        <div className='service2-0-content'>
          <h2 className='service2-title'>Enrollment</h2>
          <p className='service2-subtitle'>Start Your Academic Journey With Us</p>
          <ul className='service2-list'>
            <li>Application & Admission Process</li>
            <li>Eligibility Criteria</li>
            <li>Required Documents</li>
            <li>Enrollment Fee</li>
            <li>Payment Information</li>
          </ul>
          <button className='service2-button'>Know More</button>
        </div>
        <div className='service2-0-image-wrapper'>
          <img src={serviceimageframe2} alt="Service Frame" className='service-image' />
        </div>
      </div>

      <div className='servicesection3'>
        <div className='service2-image-wrapper'>
          <img src={serviceimageframe} alt="Service Frame" className='service-image' />
        </div>
        <div className='service2-content'>
          <h2 className='service2-title'>Dwelling</h2>
          <p className='service2-subtitle'>Finding Your Home Away From Home</p>
          <ul className='service2-list'>
            <li>Types of Dwelling</li>
            <li>Application Process for Housing</li>
            <li>Cost Of Dwelling</li>
            <li>Safety & Security</li>
            <li>Reviews & Testimonials</li>
          </ul>
          <button className='service2-button'>Know More</button>
        </div>
      </div>

      {/* Service Form Modal */}
      {isserviceVisible && (
        <div className='service-form-modal'>
            <Serviceform onClose={() => setIsServiceVisible(false)} />
        </div>
      )}
    </>
  );
};

export default ServiceSection3;
