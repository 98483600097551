import React from 'react'
import "./moreupcoming.css"
import study from "../../../assets/More/socialeventimg.png"
import downloadicon from "../../../assets/home/Icon.png"
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation } from 'swiper/modules';
const MoreUpcoming2 = () => {
    const studyPrograms = [
        {
            id: 1,
            university: 'Oxford University',
            degree: 'MSc in Advanced Computer Science',
            duration: '12 Months',
            location: 'England',
            imgSrc: study,
        },
        {
            id: 2,
            university: 'Harvard University',
            degree: 'MSc in Data Science',
            duration: '24 Months',
            location: 'USA',
            imgSrc: study,
        },
        {
            id: 3,
            university: 'MIT',
            degree: 'MSc in AI and Robotics',
            duration: '18 Months',
            location: 'USA',
            imgSrc: study,
        },
        {
            id: 4,
            university: 'Cambridge University',
            degree: 'MSc in Advanced Computer Science',
            duration: '12 Months',
            location: 'UK',
            imgSrc: study,
        }
    ];
    return (
        <div className='section6'>
            <div className='tophead6'>
                <div className='topleft newsec'>
                    <h1>Schedule <span>Events</span></h1>
                </div>
            </div>
            <div className='studyprogram'>
                <div className='swiper-button-next arrow-left upcomaingarrow'><i class="fa-solid fa-angle-left"></i></div>
                <div className='swiper-button-prev custom-arrow-right arrow-right upcomaingarrowright'><i class="fa-solid fa-chevron-right"></i></div>
                <Swiper
                    grabCursor={true}
                    centeredSlides={true}
                    modules={[Navigation]}
                    loop={true}
                    className='mySwiper'
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    breakpoints={{
                        320: {   // For phones
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        768: {   // For tablets
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {  // For laptops and larger screens
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                    }}
                >
                    {studyPrograms.map(program => (
                        <SwiperSlide key={program.id}>
                            <div className='newstudybox socialevent'>
                                <img src={program.imgSrc} alt='study' />
                                <button className='socialeventbtn'>Social Event</button>
                                <p className='socialeventpara'>The Park Hotel, 15, Sansad Marg, Hanuman Road Area, Connaught Place, New Delhi, Delhi 110001</p>
                                <div className='socialeventlastbtn'>
                                    <button className='pehlavala'>Register Now</button>
                                    <button className='dusraval'><img src={downloadicon} alt='downloadicon'/>Download Pass</button>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}

                </Swiper>
            </div>
        </div>
    )
}

export default MoreUpcoming2
