import React, { useEffect, useState } from "react";
import "./courses.css"; // Import the CSS for styling
import Collage from "../../assets/Cources/University_of_Oxford.png";

const Courses = () => {
  // State to manage which category is selected in the left menu
  const [selectedCategory, setSelectedCategory] = useState("MBA");

  // State to manage which country is selected in the country filter
  const [selectedCountry, setSelectedCountry] = useState("");

  // Data structure for categories and their respective courses
  const courseData = [
    "MBA", "Executive MBA", "MBA in Finance", "MBA in Marketing",
    "Project Management", "Operations Management", "Supply Chain Management",
    "Business Intelligence", "Artificial Intelligence", "Machine Learning",
    "Data Science", "Cloud Computing", "Cyber Security", "Database Management",
    "Network Security", "Software Development", "Blockchain Technology"
  ];

  const allCourseSData = [
    {
      imagesrc: Collage,
      location: "Harvard University",
      coursename: "MBA(90 ECTS)",
      country: "USA",
      duration_online: "1.8 year",
      duration_campus: "2 year"
    },
    {
      imagesrc: Collage,
      location: "Oxford, England",
      coursename: "MBA (90 ECTS)",
      country: "England",
      duration_online: "1.8 months",
      duration_campus: "2 year"
    },
    {
      imagesrc: Collage,
      location: "Stanford University",
      coursename: "MBA",
      country: "USA",
      duration_online: "1.6 years",
      duration_campus: "2 year"
    },
    {
      imagesrc: Collage,
      location: "MIT Sloan School of Management",
      coursename: "MBA(90 ECTS)",
      country: "USA",
      duration_online: "1.8 year",
      duration_campus: "2 year"
    },
    {
      imagesrc: Collage,
      location: "University of Toronto",
      coursename: "MBA(90 ECTS)",
      country: "Canada",
      duration_online: "1.8 year",
      duration_campus: "2 year"
    },
    {
      imagesrc: Collage,
      location: "University of Cologne",
      coursename: "MBA (90 ECTS)",
      country: "Germany",
      duration_online: "1.8 year",
      duration_campus: "2 year"
    },
    {
      imagesrc: Collage,
      location: "University of Sydney",
      coursename: "MBA (90 ECTS)",
      country: "Australia",
      duration_online: "1.8 year",
      duration_campus: "2 year"
    },
    {
      imagesrc: Collage,
      location: "University of British Columbia (Sauder)",
      coursename: "Executive MBA (90 ECTS)",
      country: "Canada",
      duration_online: "1.6 year",
      duration_campus: "2 year"
    }
    ,
    {
      imagesrc: Collage,
      location: "Mannheim Business School",
      coursename: "MBA in Finance (90 ECTS)",
      country: "Germany",
      duration_online: "1.6 year",
      duration_campus: "2 year"
    }
    ,
    {
      imagesrc: Collage,
      location: "Duke University (Fuqua)",
      coursename: "MBA in Marketing (90 ECTS)",
      country: "USA",
      duration_online: "1.6 year",
      duration_campus: "2 year"
    }
    ,
    {
      imagesrc: Collage,
      location: "University of Melbourne",
      coursename: "Project Management(90 ECTS)",
      country: "Australia",
      duration_online: "1.6 year",
      duration_campus: "2 year"
    }
    ,
    {
      imagesrc: Collage,
      location: "University of Western Australia",
      coursename: "Artificial Intelligence (90 ECTS)",
      country: "Australia",
      duration_online: "1.6 year",
      duration_campus: "2 year"
    }
    ,
    {
      imagesrc: Collage,
      location: "University of Bristol",
      coursename: "Data Science (90 ECTS)",
      country: "England",
      duration_online: "1.6 year",
      duration_campus: "2 year"
    }
  ];

  // Use useEffect to handle disabling/enabling body scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable body scroll when component mounts

    return () => {
      document.body.style.overflow = "auto"; // Re-enable body scroll when component unmounts
    };
  }, []);

  // Filter the courses based on the selected category and country
  const filteredCourses = allCourseSData.filter((course) =>
    course.coursename.includes(selectedCategory) &&
    (selectedCountry === "" || course.country === selectedCountry)
  );

  return (
    <div className="courses-container">
      {/* Left Menu */}
      <div className="courses-left-menu">
        {courseData.map((category, index) => (
          <p
            key={category}
            className={`${selectedCategory === category ? "active" : ""} ${index === 0 ? "first-left-item" : ""}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </p>
        ))}
      </div>

      {/* Right Content */}
      <div className="courses-right-content">
        {/* Dynamic Course Title */}
        <div className="course-title">
          <h2>{selectedCategory} Courses</h2> {/* Dynamic title */}
        </div>

        {/* Country Filter Section */}
        <div className="country-filter">
          {["USA", "England", "Canada", "Germany", "Australia", "New Zealand", "Ireland"].map((country) => (
            <button
              key={country}
              className={`country-button ${selectedCountry === country ? "active-country" : ""}`}
              onClick={() => setSelectedCountry(selectedCountry === country ? "" : country)} // Toggle country filter
            >
              {country}
            </button>
          ))}
        </div>

        {/* Course List */}
        <div className="course-list">
          {filteredCourses.length > 0 ? (
            filteredCourses.map((course, index) => (
              <div key={index} className="course-card">
                <img src={course.imagesrc} alt="University" className="course-image" />
                <p className="course-location">{course.location}</p>
                <p className="course-name">{course.coursename}</p>
                <div className="course-details">
                  <p>
                    <strong>Country</strong>
                    <br /> {course.country}
                  </p>
                  <p>
                    <strong>Online</strong>
                    <br /> {course.duration_online}
                  </p>
                  <p>
                    <strong>On Campus</strong>
                    <br /> {course.duration_campus}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>No courses available for the selected category and country.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Courses;
