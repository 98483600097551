import React from 'react'
import Career from "../../../assets/Career/career1.png"
import "./newcarrer.css"
const NewCarrer = () => {
    return (
        <div className='carrer'>
            <div className='carrerimg'>
                {/* Image */}
                <img src={Career} alt='Career' />
                {/* Dark Overlay */}
                <div className='overlay'></div>
            </div>
        </div>
    )
}

export default NewCarrer
