import React from 'react'
import "./section5.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
// import 'swiper/css';
import imageFrame from "../../../assets/home/1.png"
import imageFrame22 from "../../../assets/home/2.png"
import imageFrame3 from "../../../assets/home/3.png"
import imageFrame4 from "../../../assets/home/4.png"
import imageFrame5 from "../../../assets/home/5.png"
import University from "../../../assets/home/University_of_Oxford.png"
const slidesData = [
    {
        imgSrc: imageFrame3,
        description:
            'Parth’s academic journey reached a significant milestone with his graduation from Queen’s University, where he specialized in Structures, a key sub-discipline of Civil Engineering. His time at Queen’s allowed him to delve deeply into the principles of structural analysis and design, preparing him to.',
        name: 'Parth',
        qualification: 'Master of Engineering, Queen University, Canada',
        university: University,
    },
    {
        imgSrc: imageFrame22,
        description:
            "FBT Study Abroad has played a pivotal role in helping Akash  achieve his goal of studying at a prestigious institution. With a passion for business management and leadership, Akash embarked on a journey to Walsh College, renowned for its rigorous MBA program and strong industry connections. FBT's expert",
        name: 'Akash',
        qualification: 'MBA, Walsh College, Troy, Michigan, United States',
        university: University,
    },
    {
        imgSrc: imageFrame4,
        description:
            'Aditya’s academic pursuit in Industrial & System Engineering has shaped him into a forward-thinking problem-solver, ready to tackle the complexities of modern industry. Graduating with an MS in Industrial & System Engineering, Aditya has honed his skills in optimizing processes, improving operational efficiency',
        name: 'Aditya',
        qualification: 'MS in Industrial & System Engineering, Wayne State University, USA',
        university: University,
    },
    {
        imgSrc: imageFrame,
        description:
            'Tushar’s academic journey has been defined by his passion for innovation and technology, culminating in his successful completion of an MS in Electrical Engineering from the University of Melbourne, Australia. Renowned for its cutting-edge research and excellence in engineering, the University of Melbourne',
        name: 'Tushar',
        qualification: 'MS in Electrical Engineering, University of Melbourne, Australia ',
        university: University,
    },
    {
        imgSrc: imageFrame5,
        description:
            'Sunny Dutt is on the verge of embarking on an exciting new chapter in his academic career with his application for an MBA in Marketing at Unitec Institute of Technology, New Zealand. With a keen interest in shaping consumer behavior and building impactful brand strategies, Sunny’s choice of an',
        name: 'Sunny Dutt',
        qualification: 'MBA in Marketing, Unitec Institute of Technology, New Zealand ',
        university: University,
    }
];
const Section5 = () => {
    return (
        <div className='section5'>
            <div className='section5top'>
                <h1>Achievement & <span>Progress Stories</span></h1>
                <p>See How FBT Study Abroad is Making a Difference</p>
                <div className='section5-con'>
                    <Swiper
                        grabCursor={true}
                        centeredSlides={false} // Set this to false so that slides are not centered
                        modules={[Autoplay, Navigation]}
                        autoplay={{ delay: 3500 }}
                        loop={true}
                        className='newSwiper'
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                        spaceBetween={60}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 2,
                            },
                        }}
                    >
                        {slidesData.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <div className='section5-box'>
                                    <div className='section5-left'>
                                        <img src={slide.imgSrc} alt='imageFrame' />
                                    </div>
                                    <div className='section5-right'>
                                        <p>
                                            {slide.description}{' '}
                                            <span>...Read More</span>
                                        </p>
                                        <div className='nameof'>
                                            <h6 className='firstname'>{slide.name}</h6>
                                            <h6 className='firstpara'>{slide.qualification}</h6>
                                        </div>
                                        {/* <div className='university'>
                                            <img src={slide.university} alt='University' />
                                        </div> */}
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                </div>

            </div>
        </div>
    )
}

export default Section5
