import React, { useState } from 'react';
import './servicesection2.css';
import Dwelling from '../../../assets/services/Dwelling.png';
import discussion from '../../../assets/services/discussion.png';
import education from '../../../assets/services/education.png';
import stamp from '../../../assets/services/stamp.png';

const ServiceSection2 = () => {
    // State to control the animation class
    const [hovered, setHovered] = useState(null);

    return (
        <div className='servicesection2'>
            <div className='sersec2-head'>
                <h1>Your Need, <span>Our Priority</span></h1>
                <p>We Listen, We Understand, We Deliver</p>
            </div>
            <div className='services-icons'>
                {/* Service Items with hover and mouse leave events */}
                {[{ src: discussion, title: 'Counseling' }, { src: education, title: 'Scholarship' }, { src: stamp, title: 'VISA' }, { src: education, title: 'Enrollment' }, { src: Dwelling, title: 'Dwelling' }].map((item, index) => (
                    <div className='service-item' key={index}>
                        <div
                            className={`icon-border ${hovered === index ? 'rotate-clockwise' : hovered === `leave-${index}` ? 'rotate-anticlockwise' : ''}`}
                            onMouseEnter={() => setHovered(index)}
                            onMouseLeave={() => setHovered(`leave-${index}`)}
                        >
                        </div>
                        <div className='icon-image-wrapper'>
                            <img src={item.src} alt={item.title} className='service-icon' />
                        </div>
                        <p className='service-title'>{item.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServiceSection2;
