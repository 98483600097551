import React from 'react'
import "./services.css"
import Service from './Service'
import ServiceSection2 from './SerSection2/ServiceSection2'
import ServiceSection3 from './SerScetion3/ServiceSection3'

const MainServiceSec = () => {
  return (
    <div className='servicesetion'>
      <Service/>
      <ServiceSection2/>
      <ServiceSection3/>
    </div>
  )
}

export default MainServiceSec
