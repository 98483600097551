import React from 'react';
import career1st from "../../../assets/Career/career1st.png"; // Image 1
import career2nd from "../../../assets/Career/career2nd.png"; // Image 2
import career3nd from "../../../assets/Career/career3nd.png"; // Image 3
import career4nd from "../../../assets/Career/career4nd.png"; // Image 4
import career5nd from "../../../assets/Career/career5nd.png"; // Image 5
import "./newcarrer.css";

const Career3page = () => {
    return (
        <div className="career-glimpse-container">
            {/* Title */}
            <h2 className="career-glimpse-title">A Glimpse of Life at FBT</h2>

            {/* Image Gallery */}
            <div className="career-image-gallery">
                {/* Row 1: Larger Image */}
                <div className="career-image-large">
                    <img src={career1st} alt="Team Image" className="career-image" />
                </div>

                {/* Row 2: Small Images in Grid */}
                <div className="career-image-small">
                    <img src={career2nd} alt="Birthday Celebration" className="career-image" />
                    <img src={career3nd} alt="Team Gathering" className="career-image" />
                    <img src={career4nd} alt="Office Fun" className="career-image" />

                    {/* View Gallery Image with Overlay */}
                    <div className="career-view-gallery">
                        <img src={career5nd} alt="View Gallery" className="career-image" />
                        {/* <div className="view-gallery-overlay">View Gallery</div> */}
                    </div>
                </div>
            </div>

            {/* Join Our Team Section */}
            <div className="career-join-team">
                <div className="join-team-text">
                    <p className="join-team-title">Ready to work<br /> with us?</p>
                    <p className="join-team-subtitle">Join Our Team</p>
                </div>
                <button className="join-team-button">View Available Roles &rarr;</button>
            </div>

            <div className='Disclaimer'>
                <h2>Disclaimer</h2>
                <p>At FBT Study Abroad and its affiliates, we firmly uphold our policy of not charging any fees to job applicants during the recruitment process. Please</p>
                <p>be cautious of external communications that suggest otherwise. We are committed to offering a smooth and cost-free job-seeking experience, </p>
                <p>ensuring complete fairness and transparency in all our hiring practices.</p>
            </div>
        </div>
    );
};

export default Career3page;
