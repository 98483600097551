import React, { useState, useEffect } from 'react';
import "./otpsection.css";
import logoImage from '../../assets/sigin/placeholder-image.png';
import EditNumberAnother from './EditNumberAnother';

const OTPSection = () => {
    const [resendTimer, setResendTimer] = useState(30);
    const [showEdit, setEditNumber] = useState(false);

    useEffect(() => {
        let timer;
        if (resendTimer > 0) {
            timer = setTimeout(() => {
                setResendTimer(resendTimer - 1);
            }, 1000);
        }
        return () => clearTimeout(timer); // Clean up the timer
    }, [resendTimer]);

    // Function to reset the timer when Resend OTP is clicked or when Request OTP is clicked from EditNumberAnother
    const resetTimer = () => {
        setResendTimer(30);
    };

    // Function to reset the timer and go back to OTP Section
    const handleBackToOtp = () => {
        resetTimer(); // Reset timer to 30 seconds
        setEditNumber(false); // Switch back to OTP section
    };

    const handleEditNumber = () => {
        setEditNumber(true); // Switch to EditNumberAnother section
    };

    const handleResendOtp = () => {
        resetTimer(); // Reset the timer for Resend OTP
        // Additional code to resend the OTP can be added here
    };

    return (
        <>
            {showEdit ? (
                <EditNumberAnother onBackToOtp={handleBackToOtp} />
            ) : (
                <div className='anotherotpsection'>
                    <div className="otp-logo">
                        <img src={logoImage} alt="Company Logo" />
                    </div>
                    <h2 className="otp-title">Verify your phone number</h2>
                    <p className="otp-description">An OTP has been sent to your phone number</p>

                    {/* Phone Number with Edit Button */}
                    <p className="otp-number">
                        +91-9027639682<br />
                        <span className="otp-edit" onClick={handleEditNumber}>Edit</span>
                    </p>

                    {/* OTP Input Boxes */}
                    <div className="otp-input-container">
                        <input type="text" maxLength="1" className="otp-input" placeholder='*' />
                        <input type="text" maxLength="1" className="otp-input" placeholder='*' />
                        <input type="text" maxLength="1" className="otp-input" placeholder='*' />
                        <input type="text" maxLength="1" className="otp-input" placeholder='*' />
                    </div>

                    {/* Timer and Resend Button */}
                    <div className="otp-timer">
                        {resendTimer > 0 ? (
                            <span>Resend OTP in {resendTimer} seconds</span>
                        ) : (
                            <span className="resend-otp" onClick={handleResendOtp}>Resend OTP</span>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default OTPSection;
