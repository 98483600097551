import React, { useEffect, useState } from 'react';
import "./home.css"
import hero from "../../assets/home/heroimage.jpg"
import saperator from "../../assets/home/Saperator.png"
import Cources from "../../assets/home/CourcesIcon.png"
import Countries from "../../assets/home/CountriesIcon.png"
import flag from "../../assets/home/flagofindia.png"
import { toast } from "react-toastify";
import { NavLink } from 'react-router-dom';

const notifyB = (msg) => toast.success(msg);
const notifyA = (msg) => toast.error(msg);

const Section1 = () => {
  const [universityCount, setUniversityCount] = useState(0);
  const [careerCount, setCareerCount] = useState(0);
  // State for tracking selected degree
  const [selectedDegree, setSelectedDegree] = useState('');

  // States for form fields
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');

  // Handle Degree Selection
  const handleDegreeSelect = (degree) => {
    setSelectedDegree(degree);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate input fields
    if (!name || !mobile || !email || !selectedDegree) {
      notifyA('Please fill all the required fields');
      return;
    }

    // Create form data object
    const form = new FormData();
    form.append("Name", name);
    form.append("Mobile", mobile);
    form.append("Email", email);
    form.append("Degree", selectedDegree);

    // Send form data using Fetch API
    fetch("https://formspree.io/f/mpwaevwb", {
      method: "POST",
      body: form,
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          notifyB("Appointment sent successfully! We will get back to you shortly.");
          // Clear form fields on successful submission
          setSelectedDegree('');
          setName('');
          setMobile('');
          setEmail('');
        } else {
          alert("Something went wrong, please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notifyA("Failed to send appointment, please check your network and try again.");
        setSelectedDegree('');
        setName('');
        setMobile('');
        setEmail('');
      });
  };

  useEffect(() => {
    // For University Partners (100+)
    const universityInterval = setInterval(() => {
      setUniversityCount((prev) => {
        if (prev < 100) {
          return prev + 1;
        }
        clearInterval(universityInterval);
        return prev;
      });
    }, 50);


    const careerInterval = setInterval(() => {
      setCareerCount((prev) => {
        if (prev < 10) {
          return prev + 1;
        }
        clearInterval(careerInterval);
        return prev;
      });
    }, 90);

    return () => {
      clearInterval(universityInterval);
      clearInterval(careerInterval);
    };
  }, []);
  return (
    <div className='hero'>
      <div className='hero-overlay'></div>
      <div className='heroimage'>
        <img src={hero} alt='hero' />
      </div>
      <div className='allcontains'>
        <div className='leftside-hero'>
          <h1 className='leftside-head'>Global Careers Start with FBT </h1>
          <p className='leftsidepara'>Shaping Careers Worldwide</p>
          <div className='count'>
            <div className='left'>
              <div><span>{universityCount}+</span>
                <p>University Partners</p></div>
            </div>
            <div className='middle'>
              <img src={saperator} alt='saperator' />
            </div>
            <div className='right'>
              <div> <span>{careerCount.toLocaleString()}K+</span>
                <p>Careers Transformed</p></div>
            </div>
          </div>
          <NavLink to="/explorecourses" className='explore'>Explore Courses <div><i class="fa-solid fa-arrow-down"></i></div></NavLink>
        </div>
        <div className='inside-hero'>
          <p className='herobox-para'>Study Abroad on a Budget: <br />
            International Degree Made Affordable</p>
          <div className='herobox'></div>
          <div className='cources'>
            <div className='cource-left'>
              <img src={Cources} alt='cources' />
              <p>300+ Cources</p>
            </div>
            <div className='cource-right'>
              <img src={Countries} alt='cources' />
              <p>15+ Countries</p>
            </div>
          </div>
          <div className='desireddegree'>
            <p>Desired Degree <span>*</span></p>
            <div>
              <button
                style={{ backgroundColor: selectedDegree === 'Masters' ? '#292B5B' : '', color: selectedDegree === 'Masters' ? '#fff' : '', border: selectedDegree === 'Masters' ? 'none' : '' }}
                className={selectedDegree === 'Masters' ? 'selected' : ''}
                onClick={() => handleDegreeSelect('Masters')}
              >
                Masters
              </button>
              <button
                style={{ backgroundColor: selectedDegree === 'Bachelors' ? '#292B5B' : '', color: selectedDegree === 'Bachelors' ? '#fff' : '', border: selectedDegree === 'Bachelors' ? 'none' : '' }}
                className={selectedDegree === 'Bachelors' ? 'selected' : ''}
                onClick={() => handleDegreeSelect('Bachelors')}
              >
                Bachelors
              </button>
            </div>
          </div>
          <div className='details'>
            <p>Enter your details <span>*</span></p>
            <input
              type='text'
              placeholder='Name *'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='number'>
            <div className='left'>
              <div className='flag'>
                <img src={flag} alt='flag' />
                <i class="fa-solid fa-caret-down"></i>
              </div>
              <p>+91</p>
            </div>
            <div className='nuright'>
              <input
                type='tel'
                placeholder='Mobile *'
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
          </div>
          <div className='email'>
            <input
              type='email'
              placeholder='Email *'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button className='freecoun' onClick={handleSubmit}>Book Your Free Counseling</button>
          <p className='lastpara'>By submitting this form, you agree to the
            <span> Terms of Use</span> and <span>Privacy Policy</span></p>
        </div>
      </div>
    </div>
  )
}

export default Section1
