import React, { useState} from "react";
import filter from "../../../assets/home/filter.png";
import filter_blue from "../../../assets/home/filter_blue.png";
import study from "../../../assets/home/study.png";
import downloadicon from "../../../assets/home/SyllabusIcon.png";
import "./section2.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import Filter from "../../../pages/filter/Filter"; // Importing the Filter Component
import Wishlist from "../../../pages/Wishlist/Wishlist";

const Section2 = () => {
  // State for filter panel visibility
  const [iswishlistVisible, setIsWishlistVisible] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterImg, setFilterImg] = useState(filter);

  const handleWishListClick = () => {
    setIsWishlistVisible(!iswishlistVisible);
  };
  const studyPrograms = [
    {
      id: 1,
      university: "Oxford University",
      degree: "MSc in Advanced Computer Science",
      duration: "12 Months",
      location: "England",
      imgSrc: study,
    },
    {
      id: 2,
      university: "Harvard University",
      degree: "MSc in Data Science",
      duration: "24 Months",
      location: "USA",
      imgSrc: study,
    },
    {
      id: 3,
      university: "MIT",
      degree: "MSc in AI and Robotics",
      duration: "18 Months",
      location: "USA",
      imgSrc: study,
    },
    {
      id: 4,
      university: "Cambridge University",
      degree: "MSc in Machine Learning",
      duration: "12 Months",
      location: "UK",
      imgSrc: study,
    },
    {
      id: 5,
      university: "Cambridge University",
      degree: "MSc in Machine Learning",
      duration: "12 Months",
      location: "UK",
      imgSrc: study,
    },
    {
      id: 6,
      university: "Cambridge University",
      degree: "MSc in Machine Learning",
      duration: "12 Months",
      location: "UK",
      imgSrc: study,
    },
    {
      id: 7,
      university: "Cambridge University",
      degree: "MSc in Machine Learning",
      duration: "12 Months",
      location: "UK",
      imgSrc: study,
    },
    {
      id: 8,
      university: "Cambridge University",
      degree: "MSc in Machine Learning",
      duration: "12 Months",
      location: "UK",
      imgSrc: study,
    },
  ];

  const handleFilterClick = () => {
    setFilterVisible(!filterVisible);
    setFilterImg(filterVisible ? filter : filter_blue);
  };
  return (
    <>
      <div className="section2">
        <div className="tophead">
          <div className="topleft">
            <h1>
              Global <span>Study Programs</span>
            </h1>
            <p>Begin in India, Finish Top-Tier Courses in Abroad</p>
          </div>
          <div className="topright">
            <div
              className={`filte-btn ${filterVisible ? "active" : ""}`}
              onClick={handleFilterClick}
            >
              <img src={filterImg} alt="filter" />
              <p>Filters</p>
            </div>
          </div>
        </div>

        {/* Filter Panel */}
        {filterVisible && (
          <div className="filter-panel-wrapper">
            <Filter /> {/* Pass the onClose prop to Filter */}
          </div>
        )}

        <div className="studyprogram">
          <div className="swiper-button-prev arrow-left2">
            <i class="fa-solid fa-angle-left"></i>
          </div>
          <div className="swiper-button-next custom-arrow-right arrow-right2">
            <i class="fa-solid fa-chevron-right"></i>
          </div>
          <Swiper
            grabCursor={true}
            centeredSlides={true}
            modules={[Navigation]}
            loop={true}
            className="mySwipersection6"
            spaceBetween={40}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            breakpoints={{
              320: {
                // For phones
                slidesPerView: 1,
              },
              768: {
                // For tablets
                slidesPerView: 2,
              },
              1024: {
                // For laptops and larger screens
                slidesPerView: 3,
              },
            }}
          >
            {studyPrograms.map((program) => (
              <SwiperSlide key={program.id} className='studybox6slider'>
                <div className="studybox" key={program.id}>
                  <div className="likeicon" onClick={handleWishListClick}>
                    <i className="fa-regular fa-heart"></i>
                  </div>
                  <img src={program.imgSrc} alt="study" />
                  <p className="oxford">{program.university}</p>
                  <p className="msc">{program.degree}</p>
                  <div>
                    <div className="inside-icon">
                      <i className="fa-solid fa-graduation-cap"></i>
                      <p>Master’s Degree</p>
                    </div>
                    <div className="inside-icon">
                      <i className="fa-regular fa-clock"></i>
                      <p>{program.duration}</p>
                    </div>
                    <div className="inside-icon">
                      <i className="fa-solid fa-location-dot"></i>
                      <p>{program.location}</p>
                    </div>
                  </div>
                  <button className="bt1">
                    <img src={downloadicon} alt="downloadIcon" />
                    Syllabus
                  </button>
                  <button className="bt2">Check Availability</button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <button className="viewcourses">
            View All Courses <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
      </div>

      {iswishlistVisible && (
        <div className="wishlist-con">
          <Wishlist onClose={() => setIsWishlistVisible(false)} />
        </div>
      )}
    </>
  );
};

export default Section2;
