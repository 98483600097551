import React, { useState, useEffect } from 'react';
import "./serviceform.css";
import flag from "../../../assets/home/flagofindia.png";
import serviceImage from "../../../assets/wishlist/Wishlist.png"; // Adjust the path as needed

const Serviceform = ({ onClose }) => {
    const [fullName, setFullName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [nationality, setNationality] = useState('');
    const [dob, setDob] = useState('DOB');
    const [qualification, setQualification] = useState('');
    useEffect(() => {
        // Disable body scroll when the component mounts
        document.body.style.overflow = "hidden";

        // Re-enable body scroll when the component unmounts
        return () => {
            document.body.style.overflow = "auto";
        };
    }, []);

    return (
        <div className='serviceform3-container'>
            <img src={serviceImage} alt='Wishlist' className='wishlistimg2' />

            {/* Right Section - Form */}
            <div className='form-right'>
                <div className='serform'>
                    <button className="wishclose-btn2" onClick={onClose}>&times;</button>
                    <div className='form-header'>
                        <p className='form-title'>Request Form <br /><span>Study Abroad Opportunities</span></p>
                    </div>
                    <div className='form-body'>
                        <div className='form-group'>
                            <label className='form-label'>Enter your details <span>*</span></label>
                            <input
                                type='text'
                                placeholder='Full Name *'
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                className='form-input1st'
                            />
                        </div>
                        <div className='form-group phone-group'>
                            <div className='phone-left'>
                                <div className='phone-flag'>
                                    <img src={flag} alt='flag' />
                                    <i className="fa-solid fa-caret-down"></i>
                                </div>
                                <p>+91</p>
                            </div>
                            <div className='phone-right'>
                                <input
                                    type='tel'
                                    placeholder='Mobile *'
                                    className='phone-input'
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='form-group emailhai'>
                            <input
                                type='email'
                                placeholder='Email *'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='form-input1st'
                            />
                        </div>
                        <div className='form-group bachelors-group'>
                            <div className='bachelors-options otheropt'>
                                <input
                                    type='text'
                                    placeholder='Nationality *'
                                    value={nationality}
                                    onChange={(e) => setNationality(e.target.value)}
                                    className='newform-input leftnew'
                                />
                                <input
                                    type='date'
                                    placeholder='DOB'
                                    value={dob}
                                    onChange={(e) => setDob(e.target.value)}
                                    className='newform-input'
                                />
                            </div>
                        </div>
                        <div className='form-group ekorhai'>
                            <input
                                type='text'
                                placeholder='Qualification *'
                                value={qualification}
                                onChange={(e) => setQualification(e.target.value)}
                                className='form-input quali'
                            />
                        </div>
                        <button className='form-submit'>Submit</button>
                    </div>
                    <div className='form-footer2nd ndvalahai'>
                        <p>By submitting this form, you agree to the <span>Terms of Use</span> and <span>Privacy Policy</span></p>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Serviceform;
