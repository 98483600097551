import React from 'react'
import "./moresection3.css"
import Marquee from "react-fast-marquee";
import serviceimageframe from "../../../assets/More/imageframe1.png";
import serviceimageframe2 from "../../../assets/More/imageframe2.png";
import serviceimageframe4 from "../../../assets/More/imageframe4.png";
import serviceimageframe5 from "../../../assets/More/imageframe5.png";
import first from "../../../assets/More/1.png"
import second from "../../../assets/More/2.png"
import third from "../../../assets/More/3.png"
import fourth from "../../../assets/More/4.png"
import fifth from "../../../assets/More/5.png"
import sixth from "../../../assets/More/6.png"
import seventh from "../../../assets/More/7.png"
import { NavLink } from 'react-router-dom';

const MoreSection3 = () => {
    return (
        <>
            <div className='servicesection3'>
                <div className='service2-image-wrapper'>
                    <img src={serviceimageframe} alt="Service Frame" className='service-image' />
                </div>
                <div className='service2-content'>
                    <h2 className='service2-title'>Upcoming Events</h2>
                    <p className='service2-subtitle'>Showcased Highlights</p>
                    <ul className='service2-list'>
                        <li>Webinars with Alumni</li>
                        <li>Social Events</li>
                        <li>Career Fairs</li>
                        <li>Pre-Arrival Meet-up</li>
                        <li>Travel Tips & Tricks Sessions</li>
                    </ul>
                    <NavLink to="/upcomingevent" className='service3-button' >Events Schedule</NavLink>
                </div>
            </div>

            <div className='dusrahai'>
                <div className='service2-0-content'>
                    <h2 className='service2-title'>Careers</h2>
                    <p className='service2-subtitle'>Unlock Your Career Potential</p>
                    <ul className='service2-list'>
                        <li>Career Development Workshop</li>
                        <li>Skills Development</li>
                        <li>Job Opportunities</li>
                        <li>Employer Spotlights</li>
                        <li>Internship Opportunities</li>
                    </ul>
                    <button className='service2-button' >Match Your Skills</button>
                </div>
                <div className='service2-0-image-wrapper'>
                    <img src={serviceimageframe2} alt="Service Frame" className='service-image' />
                </div>
            </div>

            <div className='Universitypartner'>
                <h2>University Partners</h2>
                <p>Building Bridges Across The Borders</p>
                <div className='universityslider'>
                    <Marquee>
                        <div>
                            <img src={first} alt="" className="mt-10 ml-20 h-[100px] w-[200px] " />
                            <img src={second} alt="" />
                            <img src={third} alt="" />
                            <img src={fourth} alt="" />
                            <img src={fifth} alt="" />
                            <img src={sixth} alt="" />
                            <img src={seventh} alt="" />
                        </div>
                    </Marquee>
                </div>
            </div>

            <div className='dusrahai'>
                <div className='service2-0-content'>
                    <h2 className='service2-title'>Contacts us:</h2>
                    <p className='service2-subtitle'>Connect With Our Team</p>
                    <ul className='service2-list'>
                        <li>www.fbtstudyabroad.com</li>
                        <li>contactus@fbtstudyabroad.com</li>
                        <li>+971-58278055</li>
                        <li>+971-43271179</li>
                        <li>+91-9870343739</li>
                    </ul>
                </div>
                <div className='service2-0-image-wrapper'>
                    <img src={serviceimageframe4} alt="Service Frame" className='service-image' />
                </div>
            </div>

            <div className='servicesection3'>
                <div className='service2-image-wrapper'>
                    <img src={serviceimageframe5} alt="Service Frame" className='service-image' />
                </div>
                <div className='service2-content'>
                    <h2 className='service2-title'>About us:</h2>
                    <p className='service2-subtitle'>Meet The Team Behind Us</p>
                    <ul className='service2-list'>
                        <li>Why Choose Us</li>
                        <li>Company Introduction</li>
                        <li>Mission</li>
                        <li>Vision</li>
                        <li>Our Commitment to You</li>
                    </ul>
                    <button className='service2-button'>Know More</button>
                </div>
            </div>
        </>
    )
}

export default MoreSection3
