import React from 'react';
import Arrow from "../../../assets/about/Arrow.png"; // Imported arrow image
import "./moreabout.css";

const MoreAbout2 = () => {
  return (
    <div className="why-choose-us-container">
      {/* Section Title */}
      <h2 className="why-choose-us-title">
        Why <span className="choose-us-highlight">Choose Us</span>
      </h2>

      {/* Features List */}
      <div className="why-choose-us-features">
        {/* Left Column */}
        <div className="why-choose-us-column">
          <div className="feature-item">
            <img src={Arrow} alt="Arrow Icon" className="feature-icon" />
            <span>Industry mentorship</span>
          </div>
          <div className="feature-item">
            <img src={Arrow} alt="Arrow Icon" className="feature-icon" />
            <span>Direct Q&A forum</span>
          </div>
          <div className="feature-item">
            <img src={Arrow} alt="Arrow Icon" className="feature-icon" />
            <span>Live teaching sessions</span>
          </div>
          <div className="feature-item">
            <img src={Arrow} alt="Arrow Icon" className="feature-icon" />
            <span>Career-oriented courses</span>
          </div>
          <div className="feature-item">
            <img src={Arrow} alt="Arrow Icon" className="feature-icon" />
            <span>Save up to 80% of tuition fees</span>
          </div>
        </div>

        {/* Right Column */}
        <div className="why-choose-us-column">
          <div className="feature-item">
            <img src={Arrow} alt="Arrow Icon" className="feature-icon" />
            <span>Degree from worldwide recognized universities</span>
          </div>
          <div className="feature-item">
            <img src={Arrow} alt="Arrow Icon" className="feature-icon" />
            <span>Right leadership from the best coaches of the globe</span>
          </div>
          <div className="feature-item">
            <img src={Arrow} alt="Arrow Icon" className="feature-icon" />
            <span>One-stop destination for all doubts related to study abroad</span>
          </div>
          <div className="feature-item">
            <img src={Arrow} alt="Arrow Icon" className="feature-icon" />
            <span>Save living, transportation, and other charges for the first year</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreAbout2;
