import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom"; // Import useLocation
import "./header.css";
import logo from "../../assets/fbtstudylogo.png";
import Countries from "../../pages/Countries/Countries";
import Exams from "../../pages/Exam/Exam";
import Signin from "../../pages/SignIn/Signin";
import Courses from "../../pages/Coureses/Courses";
import SideModal from "./SideModal";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCountriesVisible, setIsCountriesVisible] = useState(false);
  const [isExamsVisible, setIsExamsVisible] = useState(false);
  const [isSigninVisible, setIsSigninVisible] = useState(false);
  const [isExploreCoursesActive, setIsExploreCoursesActive] = useState(false); // State for "Explore Courses" button
  const [activeTab, setActiveTab] = useState(""); // State for active tab

  const location = useLocation(); // Get the current URL path

  // Close "Explore Courses" when navigating to a different page using NavLink
  useEffect(() => {
    setIsExploreCoursesActive(false);
  }, [location.pathname]);

  // Toggle modal visibility for "Countries", "Exams", and "Sign In"
  const handleCountriesClick = () => {
    setIsCountriesVisible(!isCountriesVisible);
    setIsExamsVisible(false);
    setIsSigninVisible(false);
    setIsExploreCoursesActive(false); // Close Explore Courses when clicking other tabs
    setActiveTab(isCountriesVisible ? "" : "countries");
  };

  const handleExamsClick = () => {
    setIsExamsVisible(!isExamsVisible);
    setIsCountriesVisible(false);
    setIsSigninVisible(false);
    setIsExploreCoursesActive(false);
    setActiveTab(isExamsVisible ? "" : "exams");
  };

  const handleSigninClick = () => {
    setIsSigninVisible(true);
    setIsCountriesVisible(false);
    setIsExamsVisible(false);
    setIsExploreCoursesActive(false);
    setActiveTab("signin");
  };

  // Handle "Explore Courses" button click
  const handleExploreCoursesClick = () => {
    setIsExploreCoursesActive(!isExploreCoursesActive); // Toggle active state
    setIsCountriesVisible(false);
    setIsExamsVisible(false);
    setIsSigninVisible(false);
    setActiveTab(isExploreCoursesActive ? "" : "explore-courses");
  };

  // Function to close the Sign-In modal
  const handleSigninClose = () => {
    setIsSigninVisible(false);
    setActiveTab("");
  };

  return (
    <>
      <div className="header">
        <div className="inside-header">
          <NavLink to="/" className="logo">
            <img src={logo} alt="website_logo" />
          </NavLink>

          {/* Explore Courses Button */}
          <div className="explorecourcebtn">
            <button
              className={isExploreCoursesActive ? "active" : ""}
              onClick={handleExploreCoursesClick}
            >
              Explore Courses <i className="fa-solid fa-arrow-down"></i>
            </button>
            {/* <div className="line"></div> */}
          </div>

          <div className="navbar">
            <p
              className={activeTab === "countries" ? "active" : ""}
              onClick={handleCountriesClick}
            >
              Countries
            </p>
            <p
              className={activeTab === "exams" ? "active" : ""}
              onClick={handleExamsClick}
            >
              Exams
            </p>
            <NavLink
              className={activeTab === "resources" ? "active" : ""}
              to="/services"
            >
              Services
            </NavLink>
            <NavLink
              className={activeTab === "more" ? "active" : ""}
              to="/more"
            >
              More
            </NavLink>
            <p
              className={activeTab === "signin" ? "active" : ""}
              onClick={handleSigninClick}
            >
              Sign In
            </p>
          </div>
        </div>
      </div>
      <div className="phoneheader">
        <NavLink className="yetholinkhai">Cources <i class="fa-solid fa-caret-down"></i></NavLink>
        <NavLink to="/"><img src={logo} alt="website_logo" className="phonelogo"/></NavLink>
        <button onClick={() => setIsModalOpen(!isModalOpen)}> <i class="fa-solid fa-bars"></i></button>
      </div>

      {isModalOpen && <div className="sidemodelopen">
        <SideModal setIsModalOpen={setIsModalOpen} />
      </div>}

      {/* Render the Countries component as a modal */}
      {isCountriesVisible && (
        <div className="countries-modal">
          <Countries />
        </div>
      )}

      {/* Render the Exams component as a modal */}
      {isExamsVisible && (
        <div className="exams-modal">
          <Exams />
        </div>
      )}

      {/* Render the SignIn Modal */}
      {isSigninVisible && (
        <div className="Signin-modal">
          <Signin onClose={handleSigninClose} />
        </div>
      )}

      {/* Render your Explore Courses content here when active */}
      {isExploreCoursesActive && (
        <div className="explore-courses-content">
          <Courses />
        </div>
      )}
    </>
  );
};

export default Header;
