import React from 'react'
import "./moresection.css"
import Moreimg from "../../assets/More/morehero.png";
const Moresection = () => {
  return (
    <div className='morehero'>
    <div className='moreimg'>
      {/* Image */}
      <img src={Moreimg} alt='Service' />
      {/* Dark Overlay */}
      <div className='overlay'></div>
    </div>
  </div>
  )
}

export default Moresection
