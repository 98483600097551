import React from 'react';
import "./otpsection.css";
import logoImage from '../../assets/sigin/placeholder-image.png';

const EditNumberAnother = ({ onBackToOtp }) => {
    return (
        <div className='anothereditbtn'>
            <div className="otp-logo">
                <img src={logoImage} alt="Company Logo" />
            </div>

            {/* Header Text */}
            <h2 className="edit-phone-title">Edit your phone number</h2>

            {/* Input Field for Mobile Number */}
            <div className="inp-group editka">
                <input type="text" placeholder="Mobile number" className="ph-input" />
            </div>

            {/* Request OTP Button */}
            <button className="request-otp-button editkabtn" onClick={onBackToOtp}>
                Request OTP
            </button>
        </div>
    );
};

export default EditNumberAnother;
