import React from 'react'
import NewCarrer from './NewCarrer'
import Career2page from './Career2page'
import Career3page from './Career3page'

const MainCarrerPage = () => {
  return (
    <div  className='carrersection'>
      <NewCarrer/>
      <Career2page/>
      <Career3page/>
    </div>
  )
}

export default MainCarrerPage
